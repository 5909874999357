@import 'gl-variable';

html,
body {
  color: $text-color;
  background: $body-bg;//linear-gradient(to bottom, #f6f8f9, #edf1f2);
  //background: -webkit-linear-gradient(to bottom, #f6f8f9, #edf1f2);
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  margin: 0;
  padding: 0;
  padding-bottom: $padding-xl-vertical;
  height: 100%;

  transition: background-color .5s ease-in-out;
}

main {
  min-height: 100vh;
}

.h4, .h5, .h6, h4, h5, h6 {
	margin-top: 10px;
	margin-bottom: 10px;
}

:focus {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  color: gray;
  cursor: pointer;
  text-decoration: none;
}

p {
  margin: $padding-small-vertical 0;
}

span {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.no-border {
  border: 0;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.uppercase {
  text-transform: uppercase;
}

.strong {
  font-weight: $font-weight-bold;
}

.clearfix::after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mt3 {
  margin-top: 3px;
}

.mr3 {
  margin-right: 3px;
}

.mb3 {
  margin-bottom: 3px;
}

.ml3 {
  margin-left: 3px;
}

.mt5 {
  margin-top: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml10 {
  margin-left: 10px;
}

.pt3 {
  padding-top: 3px;
}

.pr3 {
  padding-right: 3px;
}

.pb3 {
  padding-bottom: 3px;
}

.pl3 {
  padding-left: 3px;
}

.pt5 {
  padding-top: 5px;
}

.pr5 {
  padding-right: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pr10 {
  padding-right: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pl10 {
  padding-left: 10px;
}

.trans-03 {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.trans-05 {
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.trans-08 {
  -webkit-transition: .8s ease-in-out;
  transition: .8s ease-in-out;
}

.hidden {
  display: none;
}

.transparent {
  opacity: 0;
}

.hidden-trans {
  display: none;
  opacity: 0;
}

.br1 {
  border-radius: $border-radius-small;
}

.br2 {
  border-radius: $border-radius-base;
}

.br4 {
  border-radius: $border-radius-large;
}

%btn {
  font-size: $font-size-small;
  border: none;
  color: $white;
  cursor: pointer;
  position: relative;
  padding: 12px 30px;
  margin: 10px 1px;
  display: inline-block;
  text-align: center;
  //font-weight: $font-weight-bold;
  border-radius: $border-radius-base;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.2);  
}

%btn:disabled {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn {
  @extend %btn;
}

.btn-round{
  border-radius: 50%;
}

.btn-lg {
  font-size: $font-size-base;
  padding: 18px 36px;
}

.btn-sm {
  padding: .25rem .5rem;
}

.btn-plain {
  @extend %btn;
  color: $gray-dark;
  background: $gray-lighter;

  &:hover {
    background: $gray-light;
  }
}

.btn-primary {
  @extend %btn;
  background: $color-primary;

  &:hover {
    background: $color-primary-dark;
    color: $white;
  }
}

.btn-simple {
  @extend %btn;

	box-shadow: none;
	background-color: transparent;
  color: $color-primary;
  
  &:hover {
    background: transparent;
  }

  &:disabled {
    background-color: transparent;
  }
}

.btn-danger {
  @extend %btn;
  background: $color-danger;

  &:hover {
    background: $color-danger-dark;
  }
}

.btn-warning {
  @extend %btn;
  background: $color-warning;

  &:hover {
    background: $color-warning-dark;
  }
}

.btn-cancel {
  color: $gray;
  font-weight: $font-weight-heavy;
  margin: 0 $padding-small-horizontal;

  &:hover {
    color: $gray-dark;
    cursor: pointer;
    text-decoration: underline;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-group > button:first-child:not(:last-child) {
	border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: 0;
}

.btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

.btn-group > button:last-child:not(:first-child) {
	border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

[type=submit] {
  border-radius: $border-radius-base;
  font-weight: $font-weight-bold;
  min-width: 100px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $screen-sm-max) {
    //height: $mobil-btn-default-height;
    padding: 9px 33px;
    width: 100%;
  }
}

.link-add {
  color: $color-primary !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.link-edit:hover {
  color: $color-primary !important;
  cursor: pointer;
}

.link-delete:hover {
  color: $color-danger !important;
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

::-webkit-input-placeholder { // Chrome/Opera/Safari
  color: $gray;
  font-size: $font-size-base;
}

::-moz-placeholder { // Firefox 19+
  color: $gray;
  font-size: $font-size-base;
}

:-ms-input-placeholder { // IE 10+
  color: $gray;
  font-size: $font-size-base;
}

.line-separator {
  border-bottom: $border-default-gray;
  height: 1px;
  margin-bottom: $padding-xl-vertical;
  margin-top: $padding-large-vertical;
}

.ell {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%tag {
  //border-radius: 50%;
  color: $white !important;
  font-size: $font-size-small;
  // font-weight: $font-weight-bold;
  font-weight: $font-weight-heavy;
  line-height: 18px;
  //height: 18px;
  //width: 18px;
  border-radius: 6px;
  padding: 2px;
}

.tag {
  @extend %tag;
}

.tag-info {
  @extend %tag;
  background: $color-info;
}

.tag-success {
  @extend %tag;
  background: $color-success;
}

.tag-default {
  @extend %tag;
  background: $gray;
}

.tag-error {
  @extend %tag;
  background: $color-danger;
}

.tag-warning {
  @extend %tag;
  background: $color-warning;
}

.small {
  -moz-transform: scale(.5, .5); // Firefox
  -ms-transform: scale(.5, .5); // IE 9
  -o-transform: scale(.5, .5); // Opera
  -webkit-transform: scale(.5, .5); // Safari and Chrome
  transform: scale(.5, .5);
}

small {
  font-weight: 300;
}

a:focus {
  outline: 0;
}

div:focus {
  outline: 0;
}

[type=checkbox]:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
              0 3px 1px -2px rgba(0,0,0,0.12),
              0 1px 5px 0 rgba(0,0,0,0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),
              0 3px 14px 2px rgba(0,0,0,0.12),
              0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),
              0 6px 30px 5px rgba(0,0,0,0.12),
              0 8px 10px -7px rgba(0,0,0,0.2);
}

/* 24dp elevation */
.z-depth-5 {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),
              0 9px 46px 8px rgba(0,0,0,0.12),
              0 11px 15px -7px rgba(0,0,0,0.2);
}

.hoverable {
  transition: box-shadow .25s;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

//@media (min-width: $screen-lg) {
//  .visible-lg-block {
//    display: block !important;
//  }
//}
//
//@media (min-width: $screen-lg) {
//  .visible-lg-inline {
//    display: inline !important;
//  }
//}
//
//@media (min-width: $screen-lg) {
//  .visible-lg-inline-block {
//    display: inline-block !important;
//  }
//}
//
//@media (max-width: $screen-xs-max) {
//  .hidden-xs {
//    display: none !important;
//  }
//}
//
//@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
//  .hidden-sm {
//    display: none !important;
//  }
//}
//
//@media (min-width: $screen-md) and (max-width: $screen-md-max) {
//  .hidden-md {
//    display: none !important;
//  }
//}
//
//@media (min-width: $screen-lg) {
//  .hidden-lg {
//    display: none !important;
//  }
//}

.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fa-error {
  color: $color-danger;
}

.fa-success {
  color: $color-success;
}

.fa-warning {
  color: $color-warning;
}

