@import 'gl-variable';

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;

    // Optimizations for mobile
    @media screen and (max-width: $screen-sm-max) {
        *,
        *:hover {
            box-shadow: none;
        }
    }
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    -webkit-touch-callout: none;
}

.gu-transit {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
}