//== Colors
//
//## Gray and gl-company-brand-form colors for use across Bootstrap.
$white:                   #fff !default;
$yellow:                  #ff0 !default;
$gray-base:               #000 !default;
$gray-darker:             lighten($gray-base, 13.5%) !default; // #222
$gray-dark:               lighten($gray-base, 20%)   !default; // #333
$gray:                    lighten($gray-base, 33.5%) !default; // #555
$gray-light:              #999999 !default; // #999999
$gray-lighter:            lighten($gray-base, 93.5%) !default; // #eee
$gray-background:         #f6f7f6 !default;
$gray-darker-trans-6:     rgba(0, 0, 0, .6);
$gray-darker-trans-2:     rgba(0, 0, 0, .2);

$darkblue-base:               #363e50;
$darkblue-darker:             #0f1828;
$darkblue-light:              #60687c;

$silver:                  #c0c0c0;

$color-people:			  #9347a2 !default;
$color-planet:			  #97b857 !default;
$color-profit:			  #5596e6 !default;
$color-product:			  #eb7a3d !default;

$color-primary:        	  #00a0d0 !default; //Blue
$color-success:           #4caf50 !default;
$color-info:              #5bc0de !default;
$color-warning:           #ffb53e !default;
$color-danger:            #f44336 !default;

$color-primary-light:	  lighten($color-primary, 20%);
$color-primary-dark:	  #00799d;
$color-danger-light:	  lighten($color-danger, 15%);
$color-danger-dark:	      darken($color-danger, 10%);
$color-warning-light:	  lighten($color-warning, 15%);
$color-warning-dark:	  darken($color-warning, 10%);
$color-success-light:	  lighten($color-success, 35%);
$color-success-dark:	  darken($color-success, 10%);


$color-people-light:	  lighten($color-people, 30%);
$color-planet-light:	  lighten($color-planet, 30%);
$color-profit-light:	  lighten($color-profit, 30%);
$color-product-light:	  lighten($color-product, 30%);

$color-people-dark:	      darken($color-people, 10%);
$color-planet-dark:	      darken($color-planet, 10%);
$color-profit-dark:	      darken($color-profit, 10%);
$color-product-dark:	  darken($color-product, 10%);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:                   #fcfcfc !default;
//** Global text color on `<body>`.
$text-color:                $gray !default;
//** Global textual link color.
$link-color:                $color-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:          darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration:     underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:    'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$font-family-serif:         'Slabo 27px', Georgia, 'Times New Roman', Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:     Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base:          $font-family-sans-serif !default;

$font-size-base:            14px;
$font-size-large:           ceil(($font-size-base * 1.14)); // ~16px
$font-size-xl:              ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:           12px; // ~12px
$font-size-xs:              10px; // ~10px

$font-size-h1:              floor(($font-size-base * 2.6)); // ~36px
$font-size-h2:              floor(($font-size-base * 2.15)); // ~30px
$font-size-h3:              ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:              ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:              $font-size-base;
$font-size-h6:              ceil(($font-size-base * .85)); // ~12px

$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-heavy:         500;
$font-weight-bold:          700;
$font-weight-extra-bold:    900;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  15px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$padding-xl-vertical:       15px !default;
$padding-xl-horizontal:     20px !default;

$padding-xxl-vertical:      20px !default;
$padding-xxl-horizontal:    25px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        2px !default;
$border-radius-large:       4px !default;
$border-radius-small:       1px !default;

$border-default-gray:       solid 1px $gray-lighter;
$border-default-silver:     solid 1px $silver;
$border-default-white:      solid 1px $white;

$navbar-height:             52px !default;

// Mobile
$mobil-btn-default-height:  45px;

// Extra small screen / phone
$screen-xxs:                 320px !default;
$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;

$screen-xxs-max:             ($screen-xs - 1) !default;
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;
$screen-mobile-max:          599px !default;

$modal-top-margin:           10%;
