@import 'gl-variable';
@import 'gl-input';
  
form {
  .category-choice {
    background-color: $white;
    color: $gray-darker;
  }

  .category-choice.people {
    &:hover {
      background-color: $color-people-light;
      color: $white;
    }

    &.active {
      background-color: $color-people;
      color: $white;
    }

    &.active:hover {
      background-color: $color-people-dark;
    }
  }

  .category-choice.planet {
    &.active {
      background-color: $color-planet;
      color: $white;
    }

    &.active:hover {
      background-color: $color-planet-dark;
    }

    &:hover {
      background-color: $color-planet-light;
      color: $white;
    }
  }

  .category-choice.profit {
    &.active {
      background-color: $color-profit;
      color: $white;
    }

    &.active:hover {
      background-color: $color-profit-dark;
    }

    &:hover {
      background-color: $color-profit-light;
      color: $white;
    }
  }

  .category-choice.product {
    &.active {
      background-color: $color-product;
      color: $white;
    }

    &.active:hover {
      background-color: $color-product-dark;
    }

    &:hover {
      background-color: $color-product-light;
      color: $white;
    }
  }

  // select {
  //   font-size: $font-size-base;
  //   padding: 0 $padding-small-horizontal;
  // }

  // input {
  //   font-size: $font-size-base;
  //   padding: 0 $padding-small-horizontal;
  // }

  // input[type=number] {
  //   -moz-appearance: textfield;
  //   -webkit-appearance: none;
  //   appearance: textfield;
  // }

  // textarea {
  //   font-size: $font-size-base;
  //   padding: $padding-small-vertical $padding-small-horizontal;
  //   resize: vertical;
  // }

  .btn-select {
    background-color: $white;
    border-left: $border-default-silver;
    border-right: 0;
    color: $gray-darker;
    font-size: $font-size-base;
    line-height: 28px;
    margin-bottom: 1rem;

    &:hover {
      background-color: $gray-lighter;
    }

    &.active {
      color: $color-primary;
    }

    &.selected {
      background: $gray;
      color: $white;
    }

    &:last-child {
      border-right: $border-default-silver;
    }
  }

  [type=file]::-webkit-file-upload-button {
    margin-top: 1px;
  }
}

.form-group {
  text-overflow: hidden;
  white-space: nowrap;
  
  label {
    cursor: default;
    font-size: .8rem;
    margin-bottom: $padding-small-vertical;
    color: $gray-light;
  }

  input[type=checkbox] + label {
    color: gray;
    font-size: 1rem;
    cursor: pointer;
  }

  .err-msg {
    margin-top: -0.7rem;
  }
}

//.ng-valid[required] {
//  border-right: 5px solid #42A948; /* green */
//}

// input.ng-invalid {
//   border-right: 5px solid $color-danger;
// }

// select.ng-invalid {
//   border-right: 5px solid $color-danger;
// }
/*
gl-country-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-company-level-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-business-level-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-business-role-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-industry-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-company-type-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-gender-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}

gl-state-selector.ng-invalid {
  select {
    border-bottom: 1px solid $color-danger;
    box-shadow: 0 1px 0 0 $color-danger;
  }
}
*/

// gl-date-picker.ng-invalid {
//   .selectiongroup {
//     .btnpicker {
//       border-right: 5px solid $color-danger;
//       margin-right: -1px;
//     }
//   }
// }

// gl-mcd-mcd-id.ng-invalid {
//   .mcd-id-row {
//     border-right: 5px solid $color-danger;
//   }
// }

.err-msg {
  color: $color-danger;
  font-size: $font-size-small;
  margin-bottom: $padding-small-vertical;
  text-align: right;
}

.success-msg {
  color: $color-success;
  font-size: $font-size-small;
  margin-top: $padding-small-vertical;
  text-align: right;
}

// input#ratio {
//   display: inline-block;
//   width: 90%;

//   @media screen and (min-width: $screen-lg) {
//     width: 95%;
//   }
// }

.interests {
  label {
    font-weight: normal;
  }
}

.mydp {
  border: none !important;
  border-bottom: 1px solid $gray-light !important;//$border-default-silver !important;
  border-radius: 0 !important;

  .selection {
    font-family: $font-family-sans-serif !important;
    //font-size: $font-size-base !important;
    //font-weight: $font-weight-normal !important;
    padding-left: 0 !important;
    text-align: left !important;
    color: $text-color !important;
  }
}

.mydp .btnclear,
.mydp .btnpicker {
  //border-left: $border-default-silver !important;
  color: $text-color !important;
}

.mydp .btnclearenabled:hover,
.mydp .btnpickerenabled:hover,
.mydp .headertodaybtn:hover,
.mydp .tablesingleday:hover {
  background-color: $gray-lighter !important;
}

.mydp .headerbtn span:hover {
  color: $gray-lighter !important;
}

// div.textarea {
//   border: $border-default-silver;
//   min-height: 12em;
//   padding: $padding-small-vertical $padding-small-horizontal;
// }

$select-background-color: transparent;
$select-padding: 4px;

/* ==========  Select Field ========== */

// Style Select Field
select {
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: $select-padding 0;
  color: $text-color;
  border: none;
  border-bottom: 1px solid $gray-light;
}

// Remove focus
select:focus {
  outline: none
}

// Use custom arrow
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
@-moz-document url-prefix() { 
  .selectfield {
      &:after {
          position: absolute;
          top: 2.75em;
          right: 0.5em;
          // Styling the down arrow
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: .25em solid transparent;
          border-right: .25em solid transparent;
          border-top: .375em solid $gray-light;
          pointer-events: none;
      }
  }
}