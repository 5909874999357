@import 'gl-variable';

.gl-stepper {
  display: flex; 
  flex-direction: row; 
  place-content: center space-between; 
  align-items: center;
  width: 50%;
  margin: auto;

  @media screen and (max-width: $screen-sm-max) {
    width: 75%;
  }

  @media screen and (max-width: $screen-xxs-max) {
    width: 100%;
  }

  .gl-step {
    flex: 0 0 auto;

    & > span {
      height: 72px; 
      color: rgba(0, 0, 0, 0.87); 
      display: flex; 
      align-items: center; 
      font-family: Roboto, sans-serif; 
      font-size: 14px; 
      padding-left: 14px; 
      padding-right: 14px; 
      font-weight: 500;

      .gl-step-icon {
        padding-right: 8px;

        svg {
          display: block; 
          color: #00a0d0; 
          fill: #00a0d0; 
          height: 24px; 
          width: 24px; 
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; 
          font-size: 24px;
        }
      }
    }

    &.gl-step-disabled {
      & > span {
        color: rgba(0, 0, 0, 0.26); 
        cursor: default;
        font-weight: initial;
  
        .gl-step-icon {
          svg {
            color: rgb(158, 158, 158); 
            fill: rgb(158, 158, 158); 
          }
        }
      }
    }

  }

  .gl-step-separator {
    flex: 1 1 auto;

    span {
      display: block; 
      border-color: rgb(189, 189, 189); 
      margin-left: -6px; 
      border-top: 1px solid rgb(189, 189, 189);
    }
  }
}