@import 'gl-variable';

.popover {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  //color: $gray-darker;
  display: none;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: $font-weight-normal;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 270px;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  z-index: 1;

  button {
    border-radius: $border-radius-base;
    font-weight: $font-weight-bold;
    margin-top: $padding-large-vertical;
    text-transform: uppercase;
  }

  p {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    margin: 0;
    text-align: left;

    span {
      font-weight: $font-weight-heavy;
    }
  }

  .popover-title {
    display: none;
  }

  .popover-body {
    background: white;
    //border: solid 1px $gray-light;
    border-radius: $border-radius-large;
    //box-shadow: 0 $padding-small-vertical $padding-small-horizontal $gray-darker-trans-2;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: $padding-small-horizontal;
    padding: $padding-large-vertical $padding-large-horizontal;
  }
}

.popover > .arrow,
.popover > .arrow::after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow::after {
  border-width: 10px;
  content: '';
}

.bs-popover-top > .arrow {
  border-bottom-width: 0;
  border-top-color: white;
  bottom: 0;
  left: 50%;
  margin-left: -11px;
}

.bs-popover-top > .arrow::after {
  border-bottom-width: 0;
  border-top-color: white;
  bottom: 1px;
  content: ' ';
  margin-left: -10px;
}

.bs-popover-right > .arrow {
  border-left-width: 0;
  border-right-color: white;
  left: 0;
  margin-top: -11px;
  top: 50%;
}

.bs-popover-right > .arrow::after {
  border-left-width: 0;
  border-right-color: white;
  bottom: -10px;
  content: ' ';
  left: 1px;
}

.bs-popover-bottom > .arrow {
  border-bottom-color: white;
  border-top-width: 0;
  left: 50%;
  margin-left: -11px;
  top: 0;
}

.bs-popover-bottom > .arrow::after {
  border-bottom-color: white;
  border-top-width: 0;
  content: ' ';
  margin-left: -10px;
  top: 1px;

}

.bs-popover-left > .arrow {
  border-left-color: white;
  border-right-width: 0;
  margin-top: -11px;
  right: 0;
  top: 50%;
}

.bs-popover-left > .arrow::after {
  border-left-color: white;
  border-right-width: 0;
  bottom: -10px;
  content: ' ';
  right: 1px;
}

.popover .virtual-area {
  height: 0 !important;
}
