@import 'gl-variable';

.gl-modal {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &::after {
    background-color: $gray-darker;
    content: '';
    height: 100%;
    left: 0;
    opacity: .4;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .modal-content {
    left: 50%;
    max-height: 80%;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: $modal-top-margin;
    transform: translate(-50%, 0);
    width: 600px;
    z-index: 101;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
    border-radius: $border-radius-large;
    //overflow: visible;

    // @media screen and (max-width: $screen-md-max) {
    //   max-height: 80%;
    //   width: 75%;
    // }

    @media screen and (max-width: $screen-xs-max) {
      max-height: 100%;
      top: 0;
      width: 100%;
    }
  }

  .modal-header {
    //border-bottom: $border-default-gray;
    color: $gray-dark;
    margin: $padding-large-horizontal $padding-large-horizontal;
    padding: 0;
    position: relative;

    .modal-title {
      font-size: $font-size-h3;
    }

    @media screen and (max-width: $screen-xs-max) {
      margin: 0;
    }

    h3 {
      margin: 0;
      //margin-bottom: $padding-xs-horizontal;
      font-weight: $font-weight-normal;
    }
  }

  .modal-close {
    //padding: $padding-xs-horizontal;
    padding: 0;
    position: relative;
    text-align: right;

    button {
      background: transparent;
      border: 0;
      color: $gray;
      //font-size: $font-size-h2;
    }

    button:hover {
      color: $gray-darker;
    }
  }

  .modal-body {
    margin: 0 $padding-large-horizontal;
    padding: 0 $padding-small-horizontal;

    @media screen and (max-width: $screen-xs-max) {
      margin: 0;
      padding-top: $padding-large-vertical;
    }

    // label {
    //   line-height: 2;
    // }
  }

  .modal-body .fa-times {
    visibility: hidden;
  }

  .modal-footer {
    position: relative;

    a:hover {
      color: $color-primary;
      text-decoration: underline;
    }

    @media screen and (max-width: $screen-xs-max) {
      margin: $padding-base-vertical 0;
      padding: 0 $padding-small-horizontal;
      text-align: center;
    }

    @media screen and (min-width: $screen-sm) {
      margin-right: $padding-xl-horizontal;
      padding: $padding-large-vertical 0;
      text-align: right;
    }

    button {
      font-weight: $font-weight-bold;
      text-transform: uppercase;

      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: $padding-large-vertical;
        width: 100%;
      }

      @media screen and (min-width: $screen-sm) {
        margin: 0 $padding-xs-horizontal;
        min-width: 100px;
      }
    }
  }

  .modal-error {
    margin: 0 $padding-large-horizontal;
    padding: 0 $padding-small-horizontal;
    padding-top: $padding-base-vertical;

    @media screen and (max-width: $screen-xs-max) {
      margin: 0;
    }

    .err-msg {
      margin-top: -$padding-large-vertical;
    }
  }

  .modal-processing {
    margin-bottom: $padding-large-vertical;
    padding: $padding-large-vertical 0;
    position: relative;
    text-align: center;
  }

  .categories {
    margin-bottom: 20px;
  }

  .categories > .category-selector {
    padding: 5px 50px 5px 50px;
    border-style: solid;
    border-color: gray;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 0px;
  }
  .category-selector:hover {
    color: #00a0d0;
  }
  .category-selected {
    color: #00a0d0;
    font-weight: 600;
    text-decoration: underline;  
  }
  .selected .gl-card {
    border: solid 1px #00a0d0;
  }
  .gl-card.hoverable {
    pointer-events: none;
  }
}
