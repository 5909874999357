@import './gl-variable';
@import './gl-animation';
@import './gl-form';
@import './gl-card';
@import './gl-modal';
@import './gl-switch';
@import './gl-default';
@import './gl-popover';
@import './gl-typography';
@import './gl-content-placeholder';
@import './gl-stepper';
@import './gl-navtabs';
@import './gl-browser';
@import './gl-dragula';

.gl-load-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-header {
  position: relative;
  background: $body-bg;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 3px 3px 0px rgba(0, 0, 0, 0.2);
  padding-left: 15px;
  padding-right: 15px; 
  min-height: $navbar-height;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;

  .title {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .subtitle {
    text-align: center;
    margin: 0;
    padding: 0;
  }


  .tool-btn {
    //margin-top: 30px;

    button {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      height: 35px;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    padding: 0;

    .tool-btn {
      margin-top: $padding-small-vertical;

      button {
        height: $mobil-btn-default-height;
        width: 100%;
      }
    }
  }
}

.tab-header {
  position: relative;
  //background: #f6f7fb;
  padding-bottom: $padding-small-vertical;
  //padding-top: $padding-xxl-vertical;
  text-align: left;

  .tool-btn {
    button {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      height: 35px;
      text-transform: uppercase;
    }

    @media screen and (max-width: $screen-xs) {
      margin-top: $padding-small-vertical;

      button {
        height: $mobil-btn-default-height;
        width: 100%;
      }
    }
  }
}

.flex-vertical-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.title {
  color: $gray-dark;
  font-size: $font-size-h3;
  //height: 51px;
  line-height: 51px;
  margin: $padding-xxl-vertical 0 0;
  padding-left: $padding-small-horizontal;
  text-align: left;
  text-transform: uppercase;
}

.subtitle {
  color: $gray-light;
  font-size: $font-size-h3;
  font-weight: $font-weight-normal;
  min-height: 40px;
  line-height: 40px;
  margin: 0;
  padding-left: $padding-small-horizontal;
  text-align: left;

  /*
  @media (min-width:62em) and (max-width:74.99em) {
    font-size: 20px;
  }

  @media print and (min-width:537px) and (max-width:61.99em),screen and (min-width:48em) and (max-width:61.99em) {
    font-size: 16px;
  }

  @media print and (max-width:536px),screen and (min-width:37.5em) and (max-width:47.99em) {
    font-size: 12px;
  }
  */
}


.properties {
  //border: $border-default-gray;

  .row {
    //border-bottom: $border-default-gray;
    margin-left: 0;
    margin-right: 0;
    padding-left: $padding-large-horizontal;
    padding-right: $padding-large-horizontal;
    text-align: left;
  }

  .edit-row:hover {
    border: $border-default-silver;
    cursor: pointer;
  }
}

.property-row {
  border-bottom: $border-default-gray;
  margin-left: 0;
  margin-right: 0;
  text-align: left;

  &:last-child {
    border-bottom: 0;
  }
}

.property-table {
  border-bottom: $border-default-gray;
  margin: 0;
  text-align: left;

  &:last-child {
    border-bottom: 0;
  }

  .key,
  .value {
    display: table-cell;
  }
}

.property-title {
  color: $gray-darker;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  padding: .5rem;
}

.key {
  color: $gray;
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  justify-content: center;
  //min-height: 40px;
  min-height: 34.3px;
  margin: 1rem 0 1rem 0;
}

.value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  //margin: 1rem 0 1rem 0;
  position: relative;
}

.hover-btn-out {
  position: relative;

  .hover-btn-in {
    display: none;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;

    button {
      padding: 0 $padding-small-horizontal;
    }
  }

  &:hover .hover-btn-in {
    display: block;
  }
}

.hover-btn-out-inline {
  .hover-btn-in-inline {
    display: none;

    button {
      padding: 0 $padding-small-horizontal;
    }
  }

  &:hover .hover-btn-in-inline {
    display: inline;
  }
}

%feature-ratio {
  color: $color-primary;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;

  span {
    color: $gray;
    font-size: $font-size-small;
    font-weight: $font-weight-heavy;
  }
}

.feature-ratio {
  @extend %feature-ratio;
}

.feature-ratio-large {
  @extend %feature-ratio;
  font-size: $font-size-h3;

  span {
    font-size: $font-size-base;
  }
}

.feature-ratio-xl {
  @extend %feature-ratio;
  font-size: $font-size-h1;

  span {
    font-size: $font-size-xl;
  }
}

%feature-number {
  color: $gray;
  font-weight: $font-weight-bold;
  text-align: center;
}

.feature-number {
  @extend %feature-number;
}

.feature-number-large {
  @extend %feature-number;
  font-size: $font-size-h3;
}

.feature-number-xl {
  @extend %feature-number;
  font-size: $font-size-h1;
}

.heavy {
  color: $gray;
  display: inline-block;
  font-weight: $font-weight-heavy;
}

$operation-row-height: 35px;

.operation {
  margin: 0;
  position: relative;

  .search-row {
    height: $operation-row-height;
    line-height: $operation-row-height;
    width: 300px;

    button {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-top: $padding-large-vertical;
      width: 100%;
    }
  }

  input {
    height: $operation-row-height;
    //line-height: $operation-row-height;
  }

  [type=submit] {
    line-height: $operation-row-height;
    padding: 0 4rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.rich-content {
  a:hover {
    color: $color-primary;
    text-decoration: underline;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  ul ul li,
  ol ul li {
    list-style-type: circle;
    padding-left: 2.5rem;
  }

  ol ol li,
  ul ol li {
    list-style-type: lower-latin;
    padding-left: 2.5rem;
  }

  ol,
  ul {
    margin: 1rem 0;
    padding-left: 2.5rem;
  }
}

.color-people {
  color: $color-people !important;
}

.color-planet {
  color: $color-planet !important;
}

.color-profit {
  color: $color-profit !important;
}

.color-product {
  color: $color-product !important;
}

.people-row {
  border-left: 8px solid $color-people;
}

.planet-row {
  border-left: 8px solid $color-planet;
}

.profit-row {
  border-left: 8px solid $color-profit;
}

.product-row {
  border-left: 8px solid $color-product;
}

.avatar-wrapper {
  background: $white;
  //border: $border-default-gray;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  img {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.default-icon {
  background-color: $color-people;
  background-image: -webkit-linear-gradient(120deg, $color-people, $color-people-light);
  background-image: linear-gradient(120deg, $color-people, $color-people-light);
  //background-image: linear-gradient(120deg, #effd2f, #7ded7e, #0cddf1);
  color: $white;
  display: block;
  overflow: hidden;
  text-align: center;

  span {
    vertical-align: text-bottom;
    line-height: 2;
  }
}

.row-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
}

.trigger-panel {
  float: left;
  position: relative;

  &.open {
    .panel {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      visibility: visible;
      opacity: 1;
    }

    .trigger {
      background: $darkblue-darker;
    }
  }

  .trigger {
    float: right;
    padding: 0px 15px;
    color: $silver;
    
    &:hover {
      color: #fff;
    }

    @media screen and (max-width: $screen-xxs-max) {
      padding: 0px 3vw;
    }
  }

  .panel {
    background: $white;
    border: $border-default-gray;
    box-shadow: 0 2px 6px $gray-darker-trans-2;
    //display: none;
    visibility: hidden;
    opacity: 0;
    padding: $padding-base-vertical $padding-base-horizontal;
    position: absolute;
    right: 0;
    z-index: 99;
    line-height: normal;
    margin-top: -20px;
    transition: all 150ms linear;

    li {
      font-size: $font-size-base;
      border-radius: 2px;
      height: 27px;
      line-height: 27px;
      text-align: left;
      white-space: nowrap;
      transition: all .3s ease-in-out;
    }

    li:hover {
      background: $gray-lighter;
    }

    a {
      color: $gray-darker;
      display: block;
      padding: 0 $padding-small-horizontal;
    }

    &.active {
      display: block;
    }
  }
}

.new-item {
  background: $white;
  font-size: 100px;
  font-weight: $font-weight-heavy;
  padding-top: 0 !important;
  text-align: center;

  p {
    font-size: $font-size-large;
  }

  &:hover {
    background: $gray-lighter;
    cursor: pointer;
  }
}

.tab-selector {
  border-bottom: 1px dotted;
  cursor: pointer;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  margin: 0 2 * $padding-xs-horizontal 2px;
  padding-bottom: 2px;

  &:hover {
    border-bottom: 4px solid $color-primary-dark;
    color: $color-primary-dark;
    margin: 0 $padding-xs-horizontal;
    padding: 0 $padding-xs-horizontal 2px;
  }

  &.selected {
    border-bottom: 4px solid $color-primary;
    color: $color-primary;
    margin: 0 $padding-xs-horizontal;
    padding: 0 $padding-xs-horizontal 2px;
  }
}

.a-tip {
  border-bottom: 1px dashed !important;
  color: $gray;
  cursor: default;
  display: inline;
  font-weight: $font-weight-bold;
  text-decoration: none !important;

  &:hover {
    border-bottom: 1px solid !important;
    color: $gray-dark;
    font-weight: $font-weight-bold;
    text-decoration: none !important;
  }
}

.icon-tip {
  color: $gray;
  cursor: default;
  display: inline-block;

  &:hover {
    color: $gray-darker;
  }
}

.a-link {
  border-bottom: 1px dotted !important;
  cursor: pointer !important;
  display: inline-block;
  text-decoration: none !important;

  &:hover {
    border-bottom: 1px solid !important;
    color: $color-primary;
    text-decoration: none !important;
  }
}

.time-input {
  height: 30px;
  line-height: 30px;
}

.sr-only {
  color: $gray;
  font-size: $font-size-small;
  font-weight: $font-weight-heavy;
  margin: 0 $padding-small-horizontal;
}

.data-feature {
  margin: 15px 0 25px;
  text-align: center;

  .feature-number {
    color: $color-primary;
    font-size: $font-size-h1;
    font-weight: $font-weight-bold;
  }

  .feature-title {
    color: $text-color;
    font-size: $font-size-xl;
  }

  .feature-subtitle {
    color: $gray;
    font-size: $font-size-base;
  }

  .feature-unit {
    color: $gray;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    margin-top: $padding-large-vertical;
  }

  &.feature-space {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.cal-char {
  font-size: $font-size-small;
  margin-bottom: $padding-large-vertical;
  margin-top: -$padding-small-vertical;
  text-align: right;
}

.two-line {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  margin: $padding-small-vertical 0;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-detail-wrapper {
  position: relative;

  &:hover {
    border: $border-default-silver;
    cursor: pointer;

    .show-detail {
      display: inline-block;
    }
  }
}

.show-detail {
  bottom: 3px;
  display: none;
  font-size: $font-size-xs;
  position: absolute;
  right: $padding-large-horizontal;
}

.no-more {
  color: $gray;
  margin-top: $padding-large-vertical;
  text-align: center;
}

.tool-panel {
  background: #fff;
  border-color: $white;
  padding: 0 $padding-small-horizontal;

  .tool-btn {
    height: 35px;
    width: 100%;
  }

  .search-row {
    input {
      height: 35px;
      margin-bottom: 0;
    }

    button {
      height: 35px;
      width: 100%;
    }
  }

  .card-header {
    border-bottom: $border-default-silver;
    font-size: $font-size-large;
    font-weight: $font-weight-heavy;
    margin: $padding-large-vertical 0;
    padding: $padding-small-vertical;
  }
}

.progress {
  border: $border-default-gray;
  margin: $padding-xxl-vertical auto;
  padding: 0 $padding-xl-horizontal;
  position: relative;

  .name {
    line-height: 50px;
    margin-right: $padding-xl-horizontal;
  }

  .progress-bar {
    background: $gray-light;
    border-left: 0;
    border-radius: 2px;
    border-right: 0;
    height: 20px;
    line-height: 50px;
    margin-bottom: $padding-xl-vertical;
    margin-top: $padding-xl-vertical;
    overflow: hidden;
    position: relative;

    .filling {
      background: $color-primary;
      color: $white;
      font-size: $font-size-small;
      height: 20px;
      left: 0;
      line-height: 22px;
      position: absolute;
      top: 0;
    }
  }
}

.title-row {
  padding: $padding-large-vertical $padding-xs-horizontal;

  .value-row {
    font-size: $font-size-h3;
    font-weight: $font-weight-bold;
  }
}

.text-warning {
  color: $color-warning;
}

.filter-tag {
  border-radius: $border-radius-base;
  display: inline-block;
  font-size: $font-size-small;
  font-weight: $font-weight-heavy;
  margin: 0 $padding-xs-horizontal $padding-small-vertical 0;
  padding: 2px 6px 3px 5px;

  &:hover,
  &.selected {
    background: $color-primary;
    color: $white;

    i {
      color: $white !important;
    }
  }

  &.selected:hover {
    background: $color-primary-dark;
  }
}

popover-content ::ng-deep .popover {
  min-width: 150px;
}

.pie-chart {
  margin: 0 auto;
  width: 52%;
}

.interests label {
  font-size: $font-size-base;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}