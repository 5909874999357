@import 'gl-variable';

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -$modal-top-margin, 0);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -$modal-top-margin, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: translate3d(-50%, -$modal-top-margin, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -$modal-top-margin, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
  }
}

@keyframes not-blink {
  0%
  {
    webkit-transform: scale(1);
	  transform: scale(1);
	  opacity: 1;
  }
  100%
  {
	  -webkit-transform: scale(2);
	  transform: scale(2);
    opacity: 0;
  }
}

.modal-fade-in {
  -webkit-animation: fadeInDown .5s;
  animation: fadeInDown .5s;

}

.modal-fade-out {
  -webkit-animation: fadeInUp .5s;
  animation: fadeInUp .5s;
}


.blink::before {
  content: "";
  -webkit-animation: not-blink 2.1s cubic-bezier(0.165,0.84,0.44,1) infinite;
  animation: not-blink 2.1s cubic-bezier(0.165,0.84,0.44,1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

.blink::before {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-box-shadow: inset 0 0 20px 5px rgba(91, 192, 222,0.4);
	box-shadow: inset 0 0 20px 5px rgba(91, 192, 222,0.4);
}

.blink::after {
  -webkit-animation: not-blink 2.1s cubic-bezier(0.65,0.815,0.735,0.395) infinite;
  animation: not-blink 2.1s cubic-bezier(0.65,0.815,0.735,0.395) infinite;
}

.blink::after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-box-shadow: inset 0 0 20px 5px rgba(91, 192, 222,0.4);
	box-shadow: inset 0 0 20px 5px rgba(91, 192, 222,0.4);
}

// gradient animation
@keyframes gradient { 
  0% {
		background-position: 0 0
	}
  100% {
		background-position: 100% 0
	}
}