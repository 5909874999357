@import 'gl-variable';

$input-height: calc(2.25rem + 2px) !default;
$input-border-color: $gray-light;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $color-danger !default;
$input-success-color: $color-success !default;
$input-focus-color: $color-primary !default;
$input-font-size: $font-size-base !default;
$input-margin-bottom: 1rem;
$input-margin: $input-margin-bottom 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$label-font-size: .8rem !default;

// Styling for input form group wrapper
.form-group {
    position: relative;
    
    input:not([type=checkbox]) + label,
    textarea + label {
      position: absolute;
      pointer-events: none;
      top: 1.5rem;
      left: 0;
      font-size: 1rem;
      cursor: text;
      transition: all .2s ease-out;
      text-align: initial;
    }

    .helper-text {
        text-align: right;
        font-size: 0.75rem;
        color: $gray-light;
        margin-top: -1rem;
    }
}

input:not([type=checkbox]):not(.selection):not(.inputfile),
textarea {
    // General Styles
    background-color: transparent;
    font-size: $input-font-size;
    margin: $input-margin;
    padding: $input-padding;
    display:block;
    width: 100%;
    border:none;
    border-bottom: $input-border;
    height: $input-height;
    box-sizing: content-box;
    transition: box-shadow .3s, border .3s;
    color: $text-color;
    
    // Focused input style
    &:focus {
      border-bottom: 1px solid $color-primary;
      box-shadow: 0 1px 0 0 $color-primary;
    }
  
    // Focused label style
    &:focus + label {
      color: $color-primary;
      top: 0;
      font-size: .8rem;
    }

    // Valid label style (Firefox)
    &:valid:not(:focus) + label,
    &:-moz-read-only + label {
      top: 0;
      font-size: .8rem;
    }

    // Valid label style
    &:valid:not(:focus) + label,
    &:read-only + label {
      top: 0;
      font-size: .8rem;
    }
}

select {
    height: calc(2.25rem + 1px);
    padding: 0;
    margin-top: -5px;

    // Focused select style
    &:focus {
        border-bottom: 1px solid $color-primary;
        box-shadow: 0 1px 0 0 $color-primary;
    }

    // Focused select label style
    &:focus + label {
        color: $color-primary;
      }
}
  
input:focus,
textarea { 
    outline:none; 
}

// input.readonly, input[readonly] {
// 	border-bottom: 1px dotted $gray-light !important;
//     color: $gray-light !important;
//     cursor: default;
// }

input:not([type=checkbox]).ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    // invalid input style
    &:focus {
        border-bottom: 1px solid $color-danger;
        box-shadow: 0 1px 0 0 $color-danger;
    }
    
    // invalid label style
    &:focus + label {
        color: $color-danger;
        //top: 0;
        //font-size: .8rem;
    }  
}

textarea {
    margin-top: 1.5rem;
}
