@import 'gl-variable';
/*
.gl-card::before {
  // Top gradient bar style
  background-color: #0cddf1;
  background-image: -webkit-repeating-linear-gradient(60deg, #9347a2, #9347a2 33%, #97b857 33%, #97b857 66%, #5596e6 66%, #5596e6 100%);//-webkit-linear-gradient(60deg, #effd2f, #7ded7e, #0cddf1);
  background-image: repeating-linear-gradient(60deg, #9347a2, #9347a2 33%, #97b857 33%, #97b857 66%, #5596e6 66%, #5596e6 100%);//linear-gradient(60deg, #effd2f, #7ded7e, #0cddf1);
  border-top-right-radius: $border-radius-large;
  border-top-left-radius: $border-radius-large;
  content: "";
  display: block;
  height: 8px;
}

.gl-card-anim::before {
  background: -webkit-linear-gradient(60deg, #effd2f, #7ded7e, #0cddf1, #7ded7e, #effd2f, #7ded7e);
  background: linear-gradient(60deg, #effd2f, #7ded7e, #0cddf1, #7ded7e, #effd2f, #7ded7e);
  background-size: 500% auto;
  background-position-x: 0%;
  -webkit-animation: gradient 1.5s linear infinite;
  -moz-animation: gradient 1.5s linear infinite;
  animation: gradient 1.5s linear infinite;
}
*/

.gl-card {
  // General styles
  position: relative;
  margin: $padding-xxl-vertical 0;
  text-align: left;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: $border-radius-large;
  background: #fff;

  &.people-card::before {
    background-color: $color-people;
    background-image: -webkit-linear-gradient(45deg,$color-people,$color-people-light);
    background-image: linear-gradient(45deg,$color-people,$color-people-light);
    border-top-right-radius: $border-radius-large;
    border-top-left-radius: $border-radius-large;
    content: "";
    display: block;
    height: 8px;
  }

  &.planet-card::before {
    background-color: $color-planet;
    background-image: -webkit-linear-gradient(45deg,$color-planet,$color-planet-light);
    background-image: linear-gradient(45deg,$color-planet,$color-planet-light);
    border-top-right-radius: $border-radius-large;
    border-top-left-radius: $border-radius-large;
    content: "";
    display: block;
    height: 8px;
  }

  &.profit-card::before {
    background-color: $color-profit;
    background-image: -webkit-linear-gradient(45deg,$color-profit,$color-profit-light);
    background-image: linear-gradient(45deg,$color-profit,$color-profit-light);
    border-top-right-radius: $border-radius-large;
    border-top-left-radius: $border-radius-large;
    content: "";
    display: block;
    height: 8px;
  }

  .gl-card-title {
    background: $white;
    border-bottom: $border-default-gray;
    padding: $padding-large-vertical $padding-large-horizontal;
    border-top-right-radius: $border-radius-large;
    border-top-left-radius: $border-radius-large;

    h3 {
      color: $gray;
      font-size: $font-size-h3;
      font-weight: $font-weight-light;
      line-height: 1.5;
      margin: 0;
    }

    h4 {
      color: $gray;
      font-size: $font-size-h4;
      font-weight: $font-weight-light;
      line-height: 1.5;
      margin: 0;
    }

    p {
      padding: $padding-large-vertical 0;
    }

    .title-button {
      border-radius: $border-radius-base;
      font-size: $font-size-xl;
      line-height: 1.5;
    }

    .tool-btn {
      font-size: $font-size-base;
      //font-weight: $font-weight-bold;
      margin-top: $padding-small-vertical;
    }

    .title-link {
      font-size: $font-size-xl;
      line-height: 1.5;

      &:hover {
        color: $color-primary;
      }

      &.close:hover {
        color: $gray-darker;
      }
    }

    &.spinner {
      min-height: 70px;
      position: relative;
    }
  }

  .gl-card-body {
    background: $white;
    padding: $padding-xl-vertical $padding-xl-horizontal;
    position: relative;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.spinner {
      min-height: 70px;
      position: relative;
    }

    .body-title {
      color: $gray-dark;
      font-size: $font-size-h3;
      line-height: 40px;
      margin: $padding-small-vertical 0;
      text-align: left;
    }
  }

  .gl-card-footer {
    background: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
    padding: $padding-small-vertical $padding-xl-horizontal;
    border-top: $border-default-gray;

    span {
      font-size: $font-size-small;
    }

    button {
      margin: 0;
    }
  }
}

.gl-card.small-card {
  margin: $padding-large-vertical 0;

  .gl-card-title {
    padding: $padding-large-vertical $padding-large-horizontal;

    h3 {
      font-size: $font-size-large;
      line-height: 1.5;
    }
  }

  .gl-card-body {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  .gl-card-footer {
    padding: $padding-small-vertical $padding-large-horizontal;
  }
}

.gl-card-photo-pl {
  background: $gray-lighter;
  margin: $padding-small-vertical 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  border-radius: 12px;

  > div {
    color: $white;
    font-size: 72px;
    line-height: 72px;
    margin-top: -36px;
    position: absolute;
    text-align: center;
    top: 50%;
    vertical-align: middle;
    width: 100%;
  }
}

.gl-card-sq-photo {
  margin: $padding-small-vertical 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 12px;

  &.crop {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    // max-width: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.gl-card-sq-photo::before {
  content: '';
  display: block;
  padding-top: 100%; // initial ratio of 1:1
}