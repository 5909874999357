@import 'gl-variable';

.switch {
  display: inline-block;
  margin-left: auto;
  height: 15px;
  margin-bottom: 0;
  position: relative;
  width: 30px;

  input {
    display: none !important;
  }

  .slider {
    background-color: $gray-light;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: .4s;
    transition: .4s;

    &.round {
      border-radius: 15px;
    }

    &.round::before {
      border-radius: 50%;
    }
  }

  .slider::before {
    background-color: $white;
    bottom: -2px;
    content: '';
    height: 20px;
    left: -10px;
    position: absolute;
    -webkit-transition: .4s;
    transition: .4s;
    width: 20px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  }

  input:checked + .slider {
    background-color: $color-primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $color-primary;
  }

  input:checked + .slider::before {
    -ms-transform: translateX(26px);
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
}
