@import 'gl-variable';

@keyframes placeHolderShimmer{
  0%{
      background-position: 0% 0
  }
  100%{
      background-position: 100% 0
  }
}

.gl-content-placeholder {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 50% 100%;//800px 104px;
  position: relative;
}

/* Every thing below this is just positioning */

.gl-content-placeholder-line {
  width: 100%;
  height: 62px;
  position: relative;
  overflow: hidden;

  &::before {
      content: '';
      position: absolute;
      width: calc(100% - 64px);
      height: 14px;
      border-radius: 6px;
      box-shadow: 0px 0px 0px 100px #fff;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }

  &.empty {
    &::before {
      height: 0px;
    }
  }

  &.wide {
    &::before {
      height: 50%;//28px;
    }
  }

  &.thin {
    height: 31px;
  }
}

.gl-content-placeholder-square {
  width: 100%;
  height: 62px;
  position: relative;
  overflow: hidden;

  &::before {
      content: '';
      position: absolute;
      width: 90%;
      height: 90%;
      border-radius: 6px;
      box-shadow: 0px 0px 0px 100px #fff;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }
}